import { formatTimeOfDay } from '@rundown-studio/timeutils'
import { useAtomValue } from 'jotai'
import PropTypes from 'prop-types'
import { timestampsAtom } from '../../../store/timestamps.store'
import { addMilliseconds } from 'date-fns'

export default function EndOfShow ({ timezone }) {
  const timestamps = useAtomValue(timestampsAtom)

  if (!timestamps) return null

  const originalEnd = addMilliseconds(timestamps.original.start, timestamps.original.duration)
  const actualEnd = addMilliseconds(timestamps.actual.start, timestamps.actual.duration)

  const diff = originalEnd - actualEnd

  return (
    <>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Planned end
        </div>
        <div className={['text-sm font-mono', Math.abs(diff/1000) !== 0 ? 'line-through' : ''].join(' ')}>{formatTimeOfDay(originalEnd, { timezone, seconds: 'nonzero' })}</div>
      </section>
      {
        Math.abs(diff/1000) !== 0 && <section>
          <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
            Calculated end
          </div>
          <div className={[
            'text-sm font-mono',
            diff >= 1000 ? 'text-green-500' : '',
            diff <= -1000 ? 'text-red-500' : '',
          ].join(' ')}>{formatTimeOfDay(actualEnd, { timezone, seconds: 'nonzero' })}</div>
        </section>
      }
    </>
  )
}

EndOfShow.propTypes = {
  timezone: PropTypes.string,
}