import { faArrowRightArrowLeft, faChevronDown, faCode, faIdBadge, faPeopleGroup, faReceipt, faRightFromBracket, faTableColumns, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { Link, NavLink, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import DashboardCheck from '../../routes/dashboard/dashboard-check'
import { userAtom, userTeamsAtom } from '../../store/user.store.js'
import UserIcon from '../auth/UserIcon'
import Logo, { LogoMark } from '../Logo'
import Orbs from '../Orbs'
import { TeamMemberRoles } from '../../constants/teamRoles'
import PropTypes from 'prop-types'
import { version } from '../../../../package.json'
import { Helmet } from 'react-helmet'
import { Menu, MenuItem, MenuSeparator } from '../interactives/DropdownMenu.jsx'


export default function DashboardWrapper({ title = '', children, ...props }) {
  const { team } = useOutletContext()
  const { teamId } = useParams()
  const [user] = useAtom(userAtom)
  // const [activeTeam] = useAtom(userTeamAtom)
  const [usersTeams] = useAtom(userTeamsAtom)
  const navigate = useNavigate()


  const isTeamAdmin = useCallback(() => {
    return team?.members?.[user?.uid]?.role === TeamMemberRoles.team_admin.name
  }, [user, team])

  const handleSwitchTeam = (teamId) => {
    localStorage.setItem('teamId', teamId)
    return navigate('/dashboard')
  }

  if (!team) return <DashboardCheck />

  return (
    <div className="flex h-screen bg-black" {...props}>
      <Helmet>
        <title>Dashboard | Rundown Studio</title>
      </Helmet>

      {/* Sidebar */}
      <aside className="flex-none flex gap-2 h-screen overflow-y-auto w-12 sm:w-48 md:w-64 flex-col items-center sm:items-start bg-black border-r border-gray-700">
        {/* Rundown Studio Logo */}
        <Link
          to={'/dashboard'}
          className="flex-none scale-75 sm:origin-left flex md:scale-100 h-[3.6rem] items-center sm:pl-2"
        >
          <div className='hidden sm:inline'><Logo /></div>
          <div className='inline sm:hidden'><LogoMark /></div>
        </Link>

        {/* Sidebar Navigation */}
        <nav className="shrink-0 grow w-full space-y-2">
          {/* Team Switcher */}
          <div className="w-full px-2">
            <p className='text-xs mb-1 text-gray-500 font-semibold'>Team</p>
            <Menu
              label={team.name || 'My team'}
              icon={usersTeams?.length > 1 && faArrowRightArrowLeft}
              className="w-full disabled:opacity-100 disabled:bg-gray-900"
              placement="right"
              disabled={usersTeams?.length === 1}
            >
              {usersTeams?.map((t) => (
                <MenuItem
                  key={t.id}
                  active={t.id === team.id}
                  label={t.name}
                  onClick={() => {
                    handleSwitchTeam(t.id)
                  }}
                />
              ))}
            </Menu>
          </div>

          <NavLink
            end // Needed in replacement of "exact"
            to={`/dashboard/${teamId}/`}
            className={({ isActive }) =>
              [
                'px-2 h-12 border-r-4 w-full gap-2 flex items-center',
                isActive ? 'bg-[#242D35] border-[#6E6EF7]' : 'border-transparent bg-transparent hover:border-[#6E6EF7] hover:bg-[#242D35]/50',
              ].join(' ')
            }
          >
            <span className='w-6 text-center'><FontAwesomeIcon icon={faTableColumns} /></span>
            <span className='hidden sm:inline'>Dashboard</span>
          </NavLink>

          <NavLink
            to={`/dashboard/${teamId}/team`}
            className={({ isActive }) =>
              [
                'px-2 h-12 border-r-4 w-full gap-2 flex items-center',
                isActive ? 'bg-[#242D35] border-[#6E6EF7]' : 'border-transparent bg-transparent hover:border-[#6E6EF7] hover:bg-[#242D35]/50',
              ].join(' ')
            }
          >
            <span className='w-6 text-center'><FontAwesomeIcon icon={faPeopleGroup} /></span>
            <span className='hidden sm:inline'>Team</span>
          </NavLink>
          {isTeamAdmin() && <NavLink
            to={`/dashboard/${teamId}/billing`}
            className={({ isActive }) =>
              [
                'px-2 h-12 border-r-4 w-full gap-2 flex items-center',
                isActive ? 'bg-[#242D35] border-[#6E6EF7]' : 'border-transparent bg-transparent hover:border-[#6E6EF7] hover:bg-[#242D35]/50',
              ].join(' ')
            }
          >
            <span className='w-6 text-center'><FontAwesomeIcon icon={faReceipt} /></span>
            <span className='hidden sm:inline'>Billing</span>
          </NavLink>}

          <NavLink
            to={`/dashboard/${teamId}/api`}
            className={({ isActive }) =>
              [
                'px-2 h-12 border-r-4 w-full gap-2 flex items-center',
                isActive ? 'bg-[#242D35] border-[#6E6EF7]' : 'border-transparent bg-transparent hover:border-[#6E6EF7] hover:bg-[#242D35]/50',
              ].join(' ')
            }
          >
            <span className='w-6 text-center'><FontAwesomeIcon icon={faCode} /></span>
            <span className='hidden sm:inline'>API</span>
          </NavLink>

          <NavLink
            to={`/dashboard/${teamId}/profile`}
            className={({ isActive }) =>
              [
                'px-2 h-12 border-r-4 w-full gap-2 flex items-center',
                isActive ? 'bg-[#242D35] border-[#6E6EF7]' : 'border-transparent bg-transparent hover:border-[#6E6EF7] hover:bg-[#242D35]/50',
              ].join(' ')
            }
          >
            <span className='w-6 text-center'><FontAwesomeIcon icon={faIdBadge} /></span>
            <span className='hidden sm:inline'>Profile</span>
          </NavLink>
        </nav>

        {/* Sidebar Footer */}
        <div className="flex-none w-full py-4 px-3 hidden sm:block">
          <div className='text-xs text-gray-400'>
            <span className='mr-1'>Need help?</span>
            <a href='https://rundownstudio.app/docs' target='_blank' rel='noreferrer' className='underline hover:text-gray-300 cursor-pointer'>Read the docs</a>.
          </div>
          <div className='text-xs text-gray-400'>
            <span className='mr-1'>Issue or feedback?</span>
            <a href='mailto:hello@rundownstudio.app' className='underline hover:text-gray-300 cursor-pointer'>Get in touch</a>.
          </div>
          <div className='text-xs text-gray-400'>
            <span className='mr-1'>Version {version}</span>
            <a href='https://rundownstudio.app/docs/updates/changelog/' target='_blank' rel='noreferrer' className='underline hover:text-gray-300 cursor-pointer'>Changelog</a>
          </div>
        </div>
      </aside>

      {/* Main */}
      <main className="grow h-screen overflow-y-auto relative">
        <header className="relative px-4 w-full z-10 h-[3.6rem] flex justify-between items-center bg-black">
          <span className=''></span>

          <Menu
            label={<UserIcon />}
            icon={faChevronDown}
            className="!bg-black hover:!bg-gray-900"
            data-label="user-menu-button"
          >
            <MenuItem
              icon={faUser}
              label={user?.email}
              disabled={true}
              data-label="user-menu-email"
            />
            <MenuSeparator />
            <MenuItem
              label="Logout"
              icon={faRightFromBracket}
              onClick={() => navigate('/logout')}
              data-label="user-menu-logout"
            />
          </Menu>
        </header>
        <section className="relative p-6">
          <h2 className="text-3xl">{title}</h2>

          <div className="mt-8 space-y-6">
            {children}
          </div>
        </section>
        <Orbs />
      </main>
    </div>
  )
}


DashboardWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.string)]),
}
