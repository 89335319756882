import PropTypes from 'prop-types'
import { CUE_BACKGROUND_COLORS } from '@rundown-studio/consts'


export default function CueColorPicker ({ onClick, disabled, rundownColors }) {

  return <div className="px-2 pt-2 gap-1 grid grid-cols-6">
    {(rundownColors || CUE_BACKGROUND_COLORS).map((option) => {
      return (
        <button
          key={option}
          disabled={disabled}
          style={{
            backgroundColor: option,
          }}
          className={[
            'w-5 h-5 hover:brightness-125 focus:outline-none focus:ring rounded border border-gray-700 overflow-hidden',
            option === 'transparent' ? 'red-crossed' : '',
          ].join(' ')}
          onClick={onClick.bind(null, option)}
        ></button>
      )
    })}
  </div>
}

CueColorPicker.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  rundownColors: PropTypes.arrayOf(PropTypes.string),
}
