import PropTypes from 'prop-types'
import CueTimingWrapper from './CueTimingWrapper.jsx'
import { Popover, PopoverContent, PopoverTrigger } from '../../interactives/Popover'
import { applyDate, formatTimeOfDay, formatTimezone, moveAfterWithTolerance } from '@rundown-studio/timeutils'
import { formatDurationHuman } from '../../../utils/formatTime.js'
import { CUE_OVERLAP_TOLERANCE } from '@rundown-studio/consts'
import TimePopoverContent from './TimePopoverContent.jsx'
import DurationPopoverContent from './DurationPopoverContent.jsx'
import TopStartTimeComponent from './TopStartTimeComponent.jsx'
import { useState, useMemo } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store.js'
import { updateRundown, updateRundownCue } from '../../../firestore.js'
import { firstCueIdAtom, getPreviousCueAtom, rundownAtom, setCueAtom } from '../../../store/rundown.store.js'
import { CueStartMode } from '@rundown-studio/types'
import { CueRunState } from '@rundown-studio/utils'
import { momentAtom } from '../../../store/moment.store.js'
import getRelativeDayString from '../../../utils/getRelativeDayString.js'
import floorMs from '../../../utils/floorMs.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import { addMilliseconds } from 'date-fns'

export default function CueTiming ({
  rundownId,
  cue,
  timezone = 'UTC',
  cellStyle,
}) {
  // Atoms and derived state
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const timestamp = getTimestampByCueId(cue.id)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)
  const previousCue = getPreviousCueById(cue.id)
  const previousCueTimestamp = getTimestampByCueId(previousCue?.id)
  const setCue = useSetAtom(setCueAtom)
  const [rundown, setRundown] = useAtom(rundownAtom)
  const firstCueId = useAtomValue(firstCueIdAtom)
  const isFirstCue = cue.id === firstCueId
  const moment = useAtomValue(momentAtom)

  // Internal state
  const [internalStartTime, setInternalStartTime] = useState(timestamp?.actual.start)
  const [internalDuration, setInternalDuration] = useState(timestamp?.actual.duration)
  const [internalStartMode, setInternalStartMode] = useState(cue.startMode || CueStartMode.FLEXIBLE)
  const [loadingStartTime, setLoadingStartTime] = useState(false)
  const [loadingDuration, setLoadingDuration] = useState(false)

  // Computed values
  const readOnly = cue.locked || RundownToken.access !== ACCESS_WRITE
  const defaultStartMode = cue.startMode || CueStartMode.FLEXIBLE

  /**
   * Memoized calculation of start time display text
   * Prevents expensive recalculation on every render
   */
  const startTimeText = useMemo(() => {
    if (!timestamp) return ''
    return getStartTimeText({
      internalStartTime,
      timestamp,
      moment,
      isFirstCue,
      internalStartMode,
      timezone,
    })
  }, [internalStartTime, timestamp?.state, moment?.total, isFirstCue, internalStartMode, timezone])

  /**
   * Memoized calculation of duration display text
   * Prevents expensive recalculation on every render
   */
  const durationText = useMemo(() => {
    if (!timestamp) return ''
    return getDurationText({
      timestamp,
      moment,
    })
  }, [timestamp?.state, moment?.left, timestamp?.actual.duration])

  /**
   * Computes the display state for time differences
   */
  const timeComparisonState = useMemo(() => {
    if (!timestamp) return 'neutral'
    const originalTime = floorMs(timestamp.original.start)
    const actualTime = floorMs(timestamp.actual.start)

    if (originalTime > actualTime) return 'early'
    if (originalTime < actualTime) return 'late'
    return 'neutral'
  }, [timestamp])

  /**
   * Determines if seconds should be shown in time display
   */
  const shouldShowSeconds = useMemo(() => {
    if (!timestamp || !moment) return 'nonzero'
    return [CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state) &&
           moment.left < 0 ? 'always' : 'nonzero'
  }, [timestamp, moment])

  /**
   * Computes the display state for duration
   */
  const durationState = useMemo(() => {
    if (!timestamp || !moment) return 'neutral'

    if (timestamp.state === CueRunState.CUE_PAST) {
      if (cue.duration > timestamp.actual.duration) return 'under'
      if (cue.duration < timestamp.actual.duration) return 'over'
    }

    if (timestamp.state === CueRunState.CUE_ACTIVE && moment.left < -1000) {
      return 'overtime'
    }

    return 'neutral'
  }, [timestamp, moment, cue.duration])

  /**
   * Calculates the current duration display text
   * Shows overtime indicator and formats duration based on cue state
   */
  const durationDisplayText = useMemo(() => {
    if (!timestamp) return ''

    const isOvertime = timestamp.state === CueRunState.CUE_ACTIVE && (moment?.left || 0) < -1000
    const duration = timestamp.state === CueRunState.CUE_ACTIVE ? (moment?.left || 0) : timestamp.actual.duration

    return [
      isOvertime ? '+' : '',
      formatDurationHuman(duration).trim(),
    ].join('')
  }, [timestamp?.state, moment?.left, timestamp?.actual.duration])

  /**
   * Calculates the display text for the top start time component
   * Shows the original start time only when it differs from the actual start time
   */
  const topStartTimeText = useMemo(() => {
    if (!timestamp) return ''

    const timesAreDifferent = floorMs(timestamp.original.start).getTime() !== floorMs(timestamp.actual.start).getTime()

    if (timesAreDifferent) return formatTimeOfDay(timestamp.original.start, { timezone, seconds: 'nonzero' })
    return ''
  }, [timestamp?.original.start, timestamp?.actual.start, timezone])

  /**
   * Calculates the display text for the top duration component
   * Shows original duration for changed future cues and actual duration for past/active cues
   */
  const topDurationText = useMemo(() => {
    if (!timestamp || !cue) return ''

    const isActiveOrPast = [CueRunState.CUE_ACTIVE, CueRunState.CUE_PAST].includes(timestamp.state)
    const hasDurationChanged = timestamp.original.duration !== timestamp.actual.duration

    if (isActiveOrPast) return formatDurationHuman(cue.duration)
    if (hasDurationChanged) return formatDurationHuman(timestamp.original.duration)
    return ''
  }, [timestamp?.state, timestamp?.original.duration, timestamp?.actual.duration, cue?.duration])

  /**
   * Determines the strikethrough state for the top duration display
   * Shows strikethrough when cue is past or when future cues have duration changes
   */
  const topDurationStrikethrough = useMemo(() => {
    if (!timestamp) return false

    const isCuePast = timestamp.state === CueRunState.CUE_PAST
    const isFutureDurationChanged = (
      [CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state) &&
      timestamp.original.duration !== timestamp.actual.duration
    )

    return isCuePast || isFutureDurationChanged
  }, [timestamp])

  /**
   * Adjusts a new start time to ensure it doesn't overlap with the previous cue
   * For first cues, sets the time directly. For other cues, ensures proper spacing based on start mode.
   *
   * @param {Date} startTime - The desired new start time
   * @returns {void}
   */
  function moveAndSetInternalStartTime (startTime) {
    if (isFirstCue) return setInternalStartTime(startTime)

    const tolerance = internalStartMode === CueStartMode.FIXED ? CUE_OVERLAP_TOLERANCE : 0
    const movedTime = moveAfterWithTolerance(
      new Date(startTime),
      previousCueTimestamp.actual.start,
      tolerance,
      { timezone },
    )
    setInternalStartTime(movedTime)
  }

  /**
   * Updates the start time of a cue in the database
   * Handles different update scenarios based on whether it's
   * the first cue or the fields that where updated.
   *
   * @returns {Promise<void>}
   */
  async function updateStartTime () {
    const timeChanged = floorMs(internalStartTime).getTime() !== floorMs(timestamp.actual.start).getTime()
    const modeChanged = (cue.startMode || CueStartMode.FLEXIBLE) !== internalStartMode
    if (!timeChanged && !modeChanged) return

    setLoadingStartTime(true)
    try {
      if (isFirstCue) {
        const { data } = await updateRundown(rundownId, {
          startTime: applyDate(internalStartTime, rundown.startTime, { timezone }),
        })
        setRundown(data)
        return
      }

      // Update values conditially, only if changed
      const updateData = {
        startTime: timeChanged ? internalStartTime : undefined,
        startMode: modeChanged ? internalStartMode : undefined,
      }
      const { data } = await updateRundownCue(rundownId, cue.id, updateData)
      setCue(data)
    } finally {
      setLoadingStartTime(false)
    }
  }

  /**
   * Updates the duration of the current cue in the database
   * Only proceeds if the duration has actually changed
   *
   * @returns {Promise<void>}
   */
  async function updateDuration () {
    if (internalDuration === timestamp.actual.duration) return

    setLoadingDuration(true)
    try {
      const { data } = await updateRundownCue(rundownId, cue.id, {
        duration: internalDuration,
      })
      setCue(data)
    } finally {
      setLoadingDuration(false)
    }
  }

  if (!timestamp) return null

  // CSS class maps for consistent styling
  const buttonClasses = {
    base: 'w-24 leading-6 rounded bg-gray-900 hover:enabled:bg-gray-800',
    loading: 'bg-animated from-gray-800 to-gray-600',
  }

  const timeTextClasses = {
    neutral: 'text-center tabular-nums',
    early: 'text-center tabular-nums text-green-600',
    late: 'text-center tabular-nums text-red-600',
  }

  const durationTextClasses = {
    neutral: 'text-center tabular-nums',
    under: 'text-center tabular-nums text-green-600',
    over: 'text-center tabular-nums text-red-600',
    overtime: 'text-center tabular-nums text-red-600',
  }

  return (
    <>
      <CueTimingWrapper
        cellStyle={cellStyle}
        top={
          <TopStartTimeComponent
            startMode={defaultStartMode}
            text={topStartTimeText}
            strikethrough={true}
          />
        }
        center={
          <Popover
            onOpen={() => {
              moveAndSetInternalStartTime(timestamp.actual.start)
              setInternalStartMode(defaultStartMode)
            }}
            onOutsideClick={updateStartTime}
            closeWithEnter={true}
            onEnterKey={updateStartTime}
          >
            <PopoverTrigger asChild={true} disabled={readOnly}>
              <button
                type="button"
                className={[
                  buttonClasses.base,
                  loadingStartTime ? buttonClasses.loading : '',
                ].join(' ')}
              >
                <p className={timeTextClasses[timeComparisonState]}>
                  {formatTimeOfDay(timestamp.actual.start, {
                    timezone,
                    seconds: shouldShowSeconds,
                  })}
                </p>
              </button>
            </PopoverTrigger>
            <PopoverContent onSave={updateStartTime}>
              <TimePopoverContent
                startTime={internalStartTime}
                timezone={timezone}
                startMode={internalStartMode}
                handleStartTimeChange={moveAndSetInternalStartTime}
                handleStartModeChange={startMode => {
                  setInternalStartMode(startMode)
                  setInternalStartTime(
                    addMilliseconds(
                      previousCueTimestamp.actual.start,
                      previousCueTimestamp.actual.duration,
                    ),
                  )
                }}
                text={startTimeText}
                disabled={[CueRunState.CUE_PAST, CueRunState.CUE_ACTIVE].includes(
                  timestamp.state,
                )}
                isFirstCue={isFirstCue}
              />
            </PopoverContent>
          </Popover>
        }
      />
      <CueTimingWrapper
        cellStyle={cellStyle}
        top={
          <TopStartTimeComponent
            text={topDurationText}
            strikethrough={topDurationStrikethrough}
          />
        }
        center={
          <Popover
            onOpen={() => setInternalDuration(timestamp.actual.duration)}
            onOutsideClick={updateDuration}
            closeWithEnter={true}
            onEnterKey={updateDuration}
          >
            <PopoverTrigger asChild={true} disabled={readOnly}>
              <button
                type="button"
                className={[
                  buttonClasses.base,
                  loadingDuration ? buttonClasses.loading : '',
                ].join(' ')}
              >
                <p className={durationTextClasses[durationState]}>
                  {durationDisplayText}
                </p>
              </button>
            </PopoverTrigger>
            <PopoverContent
              onSave={updateDuration}
              saveDisabled={timestamp.state === CueRunState.CUE_PAST}
            >
              <DurationPopoverContent
                duration={internalDuration}
                handleDurationChange={setInternalDuration}
                text={durationText}
                disabled={timestamp.state === CueRunState.CUE_PAST}
              />
            </PopoverContent>
          </Popover>
        }
      />
    </>
  )
}

CueTiming.propTypes = {
  rundownId: PropTypes.string.isRequired,
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  cellStyle: PropTypes.object,
}

/**
 * Generates a human-readable description of a cue's start time, comparing planned vs actual timing.
 *
 * This function handles two main scenarios:
 * 1. When there's no active moment (show not running) - displays planned start information
 * 2. When there's an active moment (show running) - displays actual/expected timing with variance
 *
 * @param {Object} params - The parameters object
 * @param {Date} params.internalStartTime - The current/planned start time for the cue
 * @param {Object} params.timestamp - Object containing timing information
 * @param {Object} params.timestamp.original - Original/planned timing data
 * @param {Date} params.timestamp.original.start - The originally planned start time
 * @param {string} params.timestamp.state - Current state of the cue (PAST, ACTIVE, NEXT, FUTURE)
 * @param {Object|null} params.moment - Current show timing information, null if show not running
 * @param {boolean} params.isFirstCue - Whether this is the first cue in the rundown
 * @param {string} params.internalStartMode - Start mode of the cue (FIXED or FLEXIBLE)
 * @param {string} params.timezone - Timezone for displaying times
 *
 * @returns {string} A formatted string describing the cue's timing status. Examples:
 * - "Planned hard start at 14:30:00 today (EDT)."
 * - "Started at 14:30:15 today (EDT), 15 seconds later than planned."
 * - "Expected to start at 14:30:00 today (EDT), right on time."
 */
function getStartTimeText ({
  internalStartTime,
  timestamp,
  moment,
  isFirstCue,
  internalStartMode,
  timezone,
}) {
  if (!internalStartTime) return ''

  const original = new Date(timestamp.original.start.setMilliseconds(0))
  const actual = new Date(internalStartTime)

  if (!moment) {
    const startMode = isFirstCue ? '' : internalStartMode === CueStartMode.FIXED ? 'hard' : 'soft'
    const dateString = formatTimeOfDay(actual, { timezone, seconds: 'nonzero' })
    const dayString = getRelativeDayString(actual, timezone)
    const tzString = formatTimezone(timezone, 'abbr')
    return `Planned ${startMode} start at ${dateString} ${dayString} (${tzString}).`
  }

  let intro = ''
  if ([CueRunState.CUE_PAST, CueRunState.CUE_ACTIVE].includes(timestamp.state)) {
    intro = 'Started'
  }
  if ([CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(timestamp.state)) {
    intro = 'Expected to start'
  }

  const dateString = formatTimeOfDay(actual, { timezone, seconds: 'nonzero' })
  const dayString = getRelativeDayString(actual, timezone)
  const tzString = formatTimezone(timezone, 'abbr')

  let diffString = ''
  if (original.getTime() === actual.getTime()) {
    diffString = 'right on time'
  } else {
    const diffAmountString = formatDurationHuman(original.getTime() - actual.getTime())
    diffString = original.getTime() < actual.getTime()
      ? `${diffAmountString} later than planned`
      : `${diffAmountString} earlier than planned`
  }

  return `${intro} at ${dateString} ${dayString} (${tzString}), ${diffString}.`
}

/**
 * Generates a human-readable description of a cue's duration, comparing planned vs actual run time.
 *
 * This function handles three main scenarios:
 * 1. Currently running cues that are over their planned duration
 * 2. Completed cues that ran exactly as planned
 * 3. Completed cues that ran over/under their planned duration
 *
 * @param {Object} params - The parameters object
 * @param {Object} params.timestamp - Object containing timing information
 * @param {Object} params.timestamp.original - Original/planned timing data
 * @param {number} params.timestamp.original.duration - The planned duration in milliseconds
 * @param {number} params.timestamp.actual.duration - The actual/current duration in milliseconds
 * @param {string} params.timestamp.state - Current state of the cue (PAST, ACTIVE, etc)
 * @param {Object} params.moment - Current show timing information
 * @param {number} params.moment.total - Total duration of the show
 * @param {number} params.moment.left - Time remaining in the current cue
 *
 * @returns {string} A formatted string describing the cue's duration status. Examples:
 * - "Running 2 minutes 30 seconds total."
 * - "Ran exactly 2 minutes"
 * - "Ran 30 seconds over the expected 2 minutes."
 * - "Ran 15 seconds under the expected 2 minutes."
 *
 * @returns {string|undefined} Returns undefined if the cue is not active or complete
 */
function getDurationText({
  timestamp,
  moment,
}) {
  if (!timestamp) return ''

  const original = timestamp.original.duration
  const actual = timestamp.actual.duration

  if (timestamp.state === CueRunState.CUE_ACTIVE && actual > original) {
    return `Running ${formatDurationHuman(moment.total - moment.left - 1000)} total.`
  }

  if (timestamp.state === CueRunState.CUE_PAST) {
    const originalDurationString = formatDurationHuman(original)
    const diffDurationString = formatDurationHuman(original - actual - 1000)

    if (original === actual) {
      return `Ran exactly ${originalDurationString}`
    }
    return `Ran ${diffDurationString} ${original < actual ? 'over' : 'under'} the expected ${originalDurationString}.`
  }

  return ''
}
