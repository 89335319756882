import PropTypes from 'prop-types'
import TimeInput from '../TimeInput.jsx'
import { CueStartMode } from '@rundown-studio/types'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function TimePopoverContent ({
  startTime,
  timezone = 'UTC',
  startMode,
  handleStartTimeChange = () => {},
  handleStartModeChange = () => {},
  text = '',
  disabled = false,
  isFirstCue = false,
}) {
  return (
    <div className='w-64 flex flex-col gap-2'>
      {
        isFirstCue ? (
          <div className='flex w-full justify-around py-1'>First cue</div>
        ) : (
          <div className='flex w-full justify-around'>
            <Tooltip>
              <TooltipContent>
                This cues start time is flexible and will be calculated based on the previous cues end time
              </TooltipContent>
              <TooltipTrigger className="w-1/2">
                <button
                  disabled={disabled || isFirstCue}
                  onClick={()=> handleStartModeChange(CueStartMode.FLEXIBLE)}
                  className={[
                    'text-sm h-8 rounded-l w-full disabled:pointer-events-none',
                    startMode === CueStartMode.FLEXIBLE ? 'bg-blue-500 font-semibold' : 'bg-gray-900 hover:bg-gray-800',
                  ].join(' ')}
                >
                  {startMode === CueStartMode.FLEXIBLE && <FontAwesomeIcon icon={faCheck} className='mr-1' />}
                  Soft start
                </button>
              </TooltipTrigger>
            </Tooltip>
            <Tooltip>
              <TooltipContent>
                A hard start is when you plan to start this cue manually at a specified time
              </TooltipContent>
              <TooltipTrigger className="w-1/2">
                <button
                  disabled={disabled || isFirstCue}
                  onClick={()=> handleStartModeChange(CueStartMode.FIXED)}
                  className={[
                    'text-sm h-8 rounded-r w-full disabled:pointer-events-none',
                    startMode === CueStartMode.FIXED ? 'bg-blue-500 font-semibold' : 'bg-gray-900 hover:bg-gray-800',
                  ].join(' ')}
                >
                  {startMode === CueStartMode.FIXED && <FontAwesomeIcon icon={faCheck} className='mr-1' />}
                  Hard start
                </button>
              </TooltipTrigger>
            </Tooltip>
          </div>
        )
      }
      <div className='flex pl-1'>
        <label htmlFor="startTime" className='w-16 text-sm leading-7 text-gray-200'>Time: </label>
        <TimeInput
          id="startTime"
          className="!w-full !h-7 !px-2 !rounded text-sm !bg-gray-800 hover:enabled:brightness-125"
          disabled={!isFirstCue && (disabled || startMode === CueStartMode.FLEXIBLE)}
          timezone={timezone}
          time={startTime}
          updateTime={(time) => handleStartTimeChange(time)}
        />
      </div>
      {text && <p className='text-xs text-white/80'>{text}</p>}
    </div>
  )
}

TimePopoverContent.propTypes = {
  startTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  timezone: PropTypes.string,
  startMode: PropTypes.string,
  handleStartTimeChange: PropTypes.func,
  handleStartModeChange: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isFirstCue: PropTypes.bool,
}
