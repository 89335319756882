import PropTypes from 'prop-types'
import CellItemText from './CellItemText.jsx'
import CellItemSelect from './CellItemSelect.jsx'
import { RundownToken } from '../../axios.js'
import { CELL_TEXT, CELL_SELECT } from '../../constants/cellTypes.js'
import { DEFAULT_COLUMN_WIDTH } from '../../constants/columnWidth.js'
import { ACCESS_WRITE, ACCESS_WRITE_COLUMN } from '../../constants/rundownAccessStates.js'
import { createRundownCell, updateRundownCell } from '../../firestore'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { setCellByIdAtom } from '../../store/rundown.store.js'
import { useParams } from 'react-router-dom'
import eventBus from '../../utils/eventBus.js'
import * as logger from '../../utils/logger.js'
import mitt from 'mitt'
import hash from 'object-hash'


export default function CellItem ({
  cueId,
  col,
  cell,
  readonly = false,
  style = {},
}) {
  const setCellById = useSetAtom(setCellByIdAtom)
  const { rundownId } = useParams()
  const [eventEmitter] = useState(mitt())
  const [searchParams] = useSearchParams()

  const CellComponent = {
    [CELL_TEXT]: CellItemText,
    [CELL_SELECT]: CellItemSelect,
  }[col.type] || CellItemText

  const globalWriteAccess = RundownToken.access === ACCESS_WRITE
  const columnWriteAccess = (RundownToken.access === ACCESS_WRITE_COLUMN && searchParams.get('edit-columns') === col?.id)

  const iReadonly = !(globalWriteAccess || columnWriteAccess) && readonly

  const onUpdateCell = useCallback(async (payload) => {
    if (cell?.id) {
      logger.log('[CellItem] onUpdate', cell.id)
      let updatedCell
      try {
        const { data } = await updateRundownCell(rundownId, cell.id, payload)
        updatedCell = data
      } catch (e) {/* Error already handled */}
      setCellById(updatedCell)
    } else {
      await createRundownCell(rundownId, { ...payload, columnId: col.id, cueId })
    }
  }, [cueId, col.id, cell?.id])

  /**
   * Handle 'jump' events from the `eventEmitter` that's passed down to the TipTap component
   * and loosly couple them to the KeyboardNavigationHandler through the global event bus.
   */
  useEffect(() => {
    // Propagate the `jump` event from `eventEmitter` to the global event bus
    function onJump ({ direction, event }) {
      eventBus.$emit('cellKeyboardNavigation', { cueId: cueId, colId: col.id, direction, event })
    }

    eventEmitter.on('jump', onJump)
    return () => eventEmitter.off('jump', onJump)
  }, [eventEmitter, cueId, col.id])

  /**
   * Handle 'focusCell_cue:<id>_col:<id>; event from the global event bus triggered by KeyboardNavigationHandler.
   */
  useEffect(() => {
    const eventName = `focusCell_cue:${cueId}_col:${col.id}`
    eventBus.$on(eventName, onFocusEvent)
    return () => eventBus.$off(eventName, onFocusEvent)
  }, [cueId, col.id])

  /**
   * Propagate 'focusCell' event from global event bus to the internal `eventEmitter`
   * and loosly couple this to the TipTapFocusHandler.
   */
  function onFocusEvent ({ location }) {
    eventEmitter.emit('focus', location)
  }

  const cellHash = cell ? hash(cell) : ''

  const cellComponentMemo = useMemo(() => (
    <CellComponent
      col={col}
      cell={cell}
      onUpdateCell={onUpdateCell}
      readonly={iReadonly}
      eventEmitter={eventEmitter}
      dataLabel={'cell-' + cell?.id}
    />
  ), [col, cellHash, onUpdateCell, iReadonly, eventEmitter])

  return (
    <div
      className="relative"
      style={{ ...style, width: `${col.width || DEFAULT_COLUMN_WIDTH}px` }}
    >
      {cellComponentMemo}
    </div>
  )
}

CellItem.propTypes = {
  cueId: PropTypes.string.isRequired,
  col: PropTypes.object.isRequired,
  cell: PropTypes.object, // Intentionally allowed to be undefined
  readonly: PropTypes.bool,
  style: PropTypes.object,
}
