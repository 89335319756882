
import PropTypes from 'prop-types'
import { useAtomValue } from 'jotai'
import { CueItemElapsed } from '../CueItemHeader'
import { useSearchParams } from 'react-router-dom'
import * as OUTPUT_TYPES from '../../../constants/outputConfigTypes'
import CurrentTimeOfDay from '../CurrentTimeOfDay'
import RundownTextFitWrapper from './RundownTextFitWrapper'
import { formatTimeOfDay } from '@rundown-studio/timeutils'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store'
import { addMilliseconds } from 'date-fns'
import { memo, useMemo } from 'react'


export default function RundownOutputHeroCue ({ rundown, cues, moment, runner }) {
  const [ searchParams ] = useSearchParams()

  const configStartTimes = searchParams.get(OUTPUT_TYPES.START_TIMES) || OUTPUT_TYPES.OFF
  const configEndTimes = searchParams.get(OUTPUT_TYPES.END_TIMES) || OUTPUT_TYPES.OFF
  const configLargeTimeOfDay = searchParams.get(OUTPUT_TYPES.LARGE_TIME_OF_DAY) || OUTPUT_TYPES.OFF
  const configNextCue = searchParams.get(OUTPUT_TYPES.NEXT_CUE) || OUTPUT_TYPES.OFF

  const currentCue = runner?.timesnap?.cueId ? cues[moment?.cueId] : null
  const nextCue = runner?.nextCueId ? cues[runner.nextCueId] : null

  return (
    <div className='overflow-hidden'>
      {/* FIRST */}
      <div
        style={{ height: '18vh'}}
      >
        {(configStartTimes === OUTPUT_TYPES.ON || configEndTimes === OUTPUT_TYPES.ON) && (
          <RundownTextFitWrapper height='5vh'>
            <StartEndTimeDisplay
              currentCueId={currentCue?.id}
              configStartTimes={configStartTimes}
              configEndTimes={configEndTimes}
              timezone={rundown?.timezone}
            />
          </RundownTextFitWrapper>
        )}
        { configLargeTimeOfDay === OUTPUT_TYPES.ON && (
          <RundownTextFitWrapper height='13vh'>
            <LargeTimeOfDay className='opacity-60' timezone={rundown?.timezone} />
          </RundownTextFitWrapper>
        )}
      </div>


      {/* SECOND */}
      <div
        style={{ height: '40vh'}}
      >
        <RundownTextFitWrapper height='40vh' className='font-mono font-semibold'>
          {currentCue ?
            <CueItemElapsed moment={moment} className={'!bg-transparent'} />
            :
            <span className='opacity-50'>00:00</span>
          }
        </RundownTextFitWrapper>
      </div>

      {/* THIRD */}
      <div
        style={{ height: '16vh'}}
      >
        <div
          style={{ height: '10vh'}}
        >
          <RundownTextFitWrapper height='7vh' multiLine={true}>
            {currentCue?.title}
          </RundownTextFitWrapper>
          <RundownTextFitWrapper height='4vh' multiLine={true}>
            {currentCue?.subtitle}
          </RundownTextFitWrapper>
        </div>
      </div>

      {/* FORTH */}
      <div
        style={{ height: '14vh'}}
      >
        {configNextCue === OUTPUT_TYPES.ON &&
        <RundownTextFitWrapper height='4vh' className='opacity-60'>
          {nextCue && <div className='flex items-start gap-[3svw]'
            style={{ height: '4vh'}}
          >
            <div className='bg-white text-black px-[0.5vh] font-semibold'>
              NEXT
            </div>
            <div>
              {nextCue?.title}
            </div>
          </div>}
        </RundownTextFitWrapper>}
      </div>

    </div>
  )
}

RundownOutputHeroCue.propTypes = {
  rundown: PropTypes.object,
  cues: PropTypes.object.isRequired,
  timestamps: PropTypes.object.isRequired,
  moment: PropTypes.object.isRequired,
  runner: PropTypes.object.isRequired,
}

export const LargeTimeOfDay = ({ className = 'opacity-90', timezone}) => {
  return (
    <div className={[
      'font-mono font-semibold whitespace-nowrap',
      className,
    ].join(' ')}><CurrentTimeOfDay timezone={timezone} /></div>
  )
}

LargeTimeOfDay.propTypes = {
  className: PropTypes.string,
  timezone: PropTypes.string,
}

/**
 * Shows formatted start and end times for the current cue.
 *
 * Memoized to prevent re-renders unless relevant props change
 * (currentCueId, configStartTimes, configEndTimes, timezone),
 * optimizing performance when parent component re-renders frequently.
 */
const StartEndTimeDisplay = memo(({ currentCueId, configStartTimes, configEndTimes, timezone = 'UTC' }) => {
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)

  const { startTimeText, endTimeText } = useMemo(() => {
    const timestamp = getTimestampByCueId(currentCueId)
    if (!timestamp) return {}
    const startTimeText = configStartTimes === OUTPUT_TYPES.ON ? formatTimeOfDay(timestamp.actual.start, { timezone }) : ''
    const endTimeText = configEndTimes === OUTPUT_TYPES.ON
      ? formatTimeOfDay(addMilliseconds(timestamp.actual.start, timestamp.actual.duration), { timezone })
      : ''

    return { startTimeText, endTimeText }
  }, [currentCueId])

  if (!startTimeText && !endTimeText) return null

  return (
    <div className="w-full flex items-center justify-center gap-6 font-mono leading-none">
      {startTimeText && <p>{startTimeText}</p>}
      {(startTimeText && endTimeText) && <p>→</p>}
      {endTimeText && <p>{endTimeText}</p>}
    </div>
  )
})

StartEndTimeDisplay.displayName = 'StartEndTimeDisplay'

StartEndTimeDisplay.propTypes = {
  currentCueId: PropTypes.string,
  configStartTimes: PropTypes.string.isRequired,
  configEndTimes: PropTypes.string.isRequired,
  timezone: PropTypes.string,
}