import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import Layout from '../components/Layout'
import { useEffect } from 'react'

export default function Logout () {
  const navigate = useNavigate()

  const handleLogout = async () => {
    const auth = getAuth()
    await auth.signOut()
    await new Promise(r => setTimeout(r, 500))
    return navigate('/login')
  }

  useEffect(() => {
    handleLogout()
  }, [])

  return <Layout title="Logging you out..."></Layout>
}
