import ModalWrapper from './ModalWrapper.jsx'
import PropTypes from 'prop-types'
import { memo, useRef, useState } from 'react'
import { updateRundown, uploadRundownFile } from '../../firestore.js'
import TimezoneSelect from '../interactives/TimezoneSelect.jsx'
import { updateRundownAtom } from '../../store/rundownList.store.js'
import { useSetAtom } from 'jotai'
import DateInput from '../rundown/DateInput.jsx'
import { IMAGE_TYPES, MAX_FILE_SIZE } from '../../constants/fileTypes.js'

export default memo(RundownSettingsModal, (prevProps, nextProps) => {
  // Compare only the properties that affect rendering
  return (
    prevProps.rundownId === nextProps.rundownId &&
    prevProps.startTime === nextProps.startTime &&
    prevProps.timezone === nextProps.timezone &&
    prevProps.currentCueHighlightColor === nextProps.currentCueHighlightColor &&
    prevProps.rundownLogo === nextProps.rundownLogo &&
    prevProps.eventLogo === nextProps.eventLogo &&
    prevProps.open === nextProps.open &&
    prevProps.setOpen === nextProps.setOpen
  )
})

function RundownSettingsModal ({
  rundownId,
  startTime,
  timezone,
  currentCueHighlightColor = '',
  rundownLogo,
  eventLogo,
  open,
  setOpen,
}) {
  const [loading, setLoading] = useState(false)
  const setRundownAtom = useSetAtom(updateRundownAtom)

  const logoInputRef = useRef()

  async function handleUpdateRundown (key, choice) {
    setLoading(true)
    const { data } = await updateRundown(rundownId, { [key]: choice })
    setRundownAtom(data)
    setLoading(false)
  }

  async function handleAddLogo (e) {
    const files = e.target.files

    if(!files.length) return null
    if (files.length > 1) return window.alert('Cannot add more than one file at a time.')
    const file = files[0]

    if (!IMAGE_TYPES.includes(file.type)) return window.alert('File type not supported.')
    if (file.size > MAX_FILE_SIZE) return window.alert('Filesize must be 10MB or less.')

    try {
      setLoading(true)
      const { data } = await uploadRundownFile(rundownId, file)
      await handleUpdateRundown('logo', data.url)
    } catch (e) {
      console.error('[file upload]', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalWrapper
      title="Rundown settings"
      open={[open, setOpen]}
      working={loading}
      width={500}
      buttons={[
        {
          text: 'Close',
          onClick: () => setOpen(false),
        },
      ]}
    >
      <div className="font-light space-y-4">

        {/* Date of the rundown */}
        <div>
          <p>Date</p>
          <DateInput
            className='!bg-gray-800 hover:brightness-100 !h-9 !px-3 !w-full'
            date={startTime}
            timezone={timezone}
            updateDate={(date)=> handleUpdateRundown('startTime', date)}
          />
        </div>

        {/* Timezone of the rundown */}
        <div>
          <p>Timezone</p>
          <TimezoneSelect
            className="hover:brightness-100"
            value={timezone}
            setValue={(timezone) => handleUpdateRundown('timezone', timezone)}
          />
        </div>

        {/* Color to place in the background of the active cue of the rundown */}
        <div>
          <p>Active cue highlight color</p>
          <div className='flex gap-2 py-2'>
            <button
              onClick={handleUpdateRundown.bind(null, 'settings.currentCueHighlightColor', '')}
              className={[
                'relative w-1/2 h-8 rounded border-2 flex gap-1 p-1',
                (currentCueHighlightColor === '' ? 'border-gray-300' : 'border-gray-700'),
              ].join(' ')}
            >
              <span className='bg-[#ef4444] !min-w-[4%] h-5 inline-block rounded-sm'></span>
              <span className='bg-gray-800 w-full h-5 inline-block rounded-sm'></span>
              <span className='bg-gray-800 w-full h-5 inline-block rounded-sm'></span>
              <span className='absolute text-sm font-semibold left-0 right-0'>
                <span className='bg-gray-900/70 rounded-sm px-1'>Subtle</span>
              </span>
            </button>
            <button
              onClick={handleUpdateRundown.bind(null, 'settings.currentCueHighlightColor', '#ef4444')}
              className={[
                'relative w-1/2 h-8 rounded border-2 flex gap-1 p-1',
                (currentCueHighlightColor === '#ef4444' ? 'border-gray-300' : 'border-gray-700'),
              ].join(' ')}
            >
              <span className='bg-[#ef4444] min-w-[4%] h-5 inline-block rounded-sm'></span>
              <span className='bg-[#ef4444] w-full h-5 inline-block rounded-sm'></span>
              <span className='bg-[#ef4444] w-full h-5 inline-block rounded-sm'></span>
              <span className='absolute text-sm font-semibold left-0 right-0'>
                <span className='bg-gray-900/70 rounded-sm px-1'>Full</span>
              </span>
            </button>
          </div>
        </div>

        {/* Logo used on the rundown */}
        <div>
          <p>Logo</p>
          <LogoPreview src={rundownLogo || eventLogo || '/img/logo.svg'} />
          <div className='space-x-2 text-sm text-gray-400'>
            <button
              onClick={() => logoInputRef.current.click()}
            >
              Change
            </button>
            <button
              onClick={handleUpdateRundown.bind(null, 'logo', '')}
              className='disabled:hidden'
              disabled={!rundownLogo}
            >
              Reset
            </button>
            <input
              ref={logoInputRef}
              type='file'
              className='hidden'
              accept={IMAGE_TYPES}
              onChange={handleAddLogo}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

RundownSettingsModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  startTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  timezone: PropTypes.string,
  currentCueHighlightColor: PropTypes.string,
  rundownLogo: PropTypes.string,
  eventLogo: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

function LogoPreview ({ src }) {
  return (
    <div className='my-1 py-1 bg-gray-950 rounded border border-gray-800 flex justify-center'>
      <img src={src} className='h-12 object-contain max-w-[14rem]' />
    </div>
  )
}


LogoPreview.propTypes = {
  src: PropTypes.string,
}
