import { useAtomValue } from 'jotai'
import { timestampsAtom } from '../../../store/timestamps.store'
import { addMilliseconds } from 'date-fns'
import { formatDurationHuman } from '../../../utils/formatTime'

export default function OverUnder () {
  const timestamps = useAtomValue(timestampsAtom)

  if (!timestamps) return null

  const actualEnd = addMilliseconds(timestamps.actual.start, timestamps.actual.duration)
  const originalEnd = addMilliseconds(timestamps.original.start, timestamps.original.duration)

  const diff = originalEnd - actualEnd

  return (
    <section>
      <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
        <p>Over / Under</p>
      </div>
      <span className='text-sm leading-6'>
        {diff >= 1000 ? <p className='text-green-500'><span className='font-mono'>{formatDurationHuman(diff)}</span> early</p> : ''}
        {diff < 1000 && diff > -1000 ? '--' : ''}
        {diff <= -1000 ? <p className='text-red-500'><span className='font-mono'>{formatDurationHuman(diff)}</span> late</p> : ''}
      </span>
    </section>
  )
}
