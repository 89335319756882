import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import Layout from '../components/Layout'
import Input from '../components/Input'
import Button from '../components/Button'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../store/toast.store.js'
import { newsletterSubscribe, updateAirtable } from '../firestore'
import * as GtagService from '../utils/GtagService.js'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [heardFrom, setHeardFrom] = useState('')
  const [signingUp, setSigningUp] = useState(false)
  const [emailUpdates, setEmailUpdates] = useState(false)
  const addToast = useSetAtom(toastAtom)

  const navigate = useNavigate()

  const handleSignup = async (e) => {
    e.preventDefault()

    if (!email || !password) return null

    setSigningUp(true)

    const auth = getAuth()

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async () => {
        // Update Airtable if use has set the heardFrom field
        if (heardFrom) {
          await updateAirtable({ payload: { 'Heard From': heardFrom } })
        }

        if (emailUpdates) {
          await newsletterSubscribe()
        }

        // Register conversion event
        GtagService.signup()

        const params = new URLSearchParams(window.location.search)
        const next = params.get('next')
        if (next) return navigate(next)

        return navigate('/dashboard')
      })
      .catch((error) => {
        const errorCode = error.code
        setSigningUp(false)
        switch (errorCode) {
          case 'auth/email-already-in-use':
            addToast({ title: 'This user already exists', type: 'fail' })
            break

          case 'auth/weak-password':
            addToast({ title: 'Enter a more secure password', type: 'fail' })
            break

          default:
            addToast({ title: 'An error occured', body: 'Please try again', type: 'fail' })
            break
        }
      })
  }
  return (
    <Layout title="Create an account">
      <form onSubmit={(e) => handleSignup(e)} className="flex flex-col gap-4">
        <Input label="Email" type="email" placeholder="me@email.com" autofocus={true} onChange={(change) => setEmail(change)} value={email} />
        <Input label="Password" type="password" placeholder="••••••••••" onChange={(change) => setPassword(change)} value={password} />
        <Input
          label="Where did you hear about Rundown Studio?"
          type="text"
          placeholder="Your answer"
          onChange={(change) => setHeardFrom(change)}
          value={heardFrom}
        />
        <label
          htmlFor={'emailUpdates'}
          className="p-2 mt-2 bg-gray-900 rounded flex gap-2 cursor-pointer hover:brightness-110"
        >
          <input
            id={'emailUpdates'}
            type="checkbox"
            className="focus:outline-none focus:ring"
            checked={emailUpdates}
            onChange={(e) => setEmailUpdates(e.target.checked)}
          />
          <span className='text-sm'>Send me product updates. No spam.</span>
        </label>
        <p
          className='text-xs text-gray-500'
        >By continuing, you are indicating that you accept our {' '}
          <a className='underline hover:text-gray-400' href='https://rundownstudio.app/terms-of-service' target='_blank' rel="noreferrer">Terms of Service</a> {' '}
          and{' '}
          <a className='underline hover:text-gray-400' href='https://rundownstudio.app/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>.</p>
        <Button
          disabled={!email || !password || signingUp}
          loading={signingUp}
          text={<span>{signingUp ? 'Creating your account...' : 'Create account'}</span>}
          onClick={() => {}}
          type='submit'
        />
      </form>
      <p className="text-sm text-gray-200">
        Already have an account?{' '}
        <Link to="/login" className="text-blue-500 hover:underline">
          Log in here
        </Link>
      </p>
    </Layout>
  )
}
