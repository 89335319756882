import Logo from '../Logo.jsx'
import EditableInput from '../EditableInput.jsx'
import RundownCollaborateModal from '../modal/RundownCollaborateModal.jsx'
import RundownOutputModal from '../modal/RundownOutputModal.jsx'
import RundownExportModal from '../modal/RundownExportModal.jsx'
import RundownTimezoneModal from '../modal/RundownTimezoneModal.jsx'
import ConnectionStatus from './ConnectionStatus.jsx'
import RundownMenu from './RundownMenu.jsx'
import { generateCsv, unsetRundownRunner, updateRundown } from '../../firestore'
import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import RundownStatus from './RundownStatus'
import { useAtomValue, useSetAtom } from 'jotai'
import { rundownAtom } from '../../store/rundown.store.js'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates'
import { RundownToken } from '../../axios.js'
import eventBus from '../../utils/eventBus.js'
import { format } from 'date-fns'
import { toastAtom } from '../../store/toast.store.js'
import { EXPORT } from '../../constants/billingTypes.js'
import { planAtom } from '../../store/plan.store.js'
import UpgradeModal from '../modal/UpgradeModal'
import RundownSettingsModal from '../modal/RundownSettingsModal.jsx'

export default function RundownNavbar({ rundown, event }) {
  const [collaborateModalOpen, setCollaborateModalOpen] = useState(false)
  const [outputModalOpen, setOutputModalOpen] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  const [timezoneModalOpen, setTimezoneModalOpen] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const plan = useAtomValue(planAtom)

  const addToast = useSetAtom(toastAtom)

  const setRundown = useSetAtom(rundownAtom)
  const writeAccess = RundownToken.access === ACCESS_WRITE

  // Open timezone modal through button click or global event
  const handleOpenTimezone = useCallback(() => setTimezoneModalOpen(true))

  useEffect(() => {
    eventBus.$on('openTimezoneModal', handleOpenTimezone)
    return () => eventBus.$off('openTimezoneModal', handleOpenTimezone)
  }, [handleOpenTimezone])

  async function handleDownloadCsv () {
    // User is on a plan which does not include exporting
    if(!plan.features.includes(EXPORT)) {
      return setShowUpgradeModal(true)
    }

    try {
      const response = await generateCsv(rundown.id)

      // Create blob with csv
      const blob = new Blob([response.data], { type: 'text/csv' })

      // Create a with content to download csv
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.rel = 'noreferrer'
      link.download = `${rundown.name}-${format(Date.now(), 'yyyyMMdd-HHmm')}.csv`
      link.click()
    } catch (error) {
      addToast({ title: 'Error downloading the CSV', type: 'fail' })
      console.error('Error downloading the CSV:', error)
    }
  }

  function handleResetRunner () {
    updateRundown(rundown.id, { startCueId: null }).then(({ data }) => setRundown(data))
    unsetRundownRunner(rundown.id)
  }


  return (
    <>
      <nav className="h-full bg-black flex items-center gap-2">
        <Link to={
          event ?
            rundown.eventId && rundown.teamId ? `/dashboard/${rundown.teamId}/event/${rundown.eventId}` : '/dashboard'
            :
            '/dashboard'
        }>
          <img src={rundown.logo || event?.logo || '/img/logo.svg'} className='p-1 h-10 object-contain' />
        </Link>
        <span className="flex-grow"></span>
        {event &&
        <>
          <Link
            to={`/dashboard/${rundown.teamId}/event/${rundown.eventId}`}
            className='text-gray-400 hover:text-white transition-colors text-sm'
          >{event.name || 'Event'}</Link>
          <p className='text-gray-500 text-sm'>/</p>
        </>
        }
        <EditableInput
          className="text-sm whitespace-pre truncate max-w-[300px] h-7 px-2 hover:bg-gray-900 focus:bg-gray-900"
          value={rundown.name}
          onChange={(change) => updateRundown(rundown.id, { name: change })}
          disabled={!writeAccess}
        />
        <div className="relative">
          <RundownStatus rundown={rundown} onUpdate={setRundown} disabled={!writeAccess} />
        </div>
        <span className="flex-grow"></span>
        <div className="px-2">
          <ConnectionStatus />
        </div>
        <RundownMenu
          teamId={rundown.teamId}
          eventId={rundown.eventId}
          onOpenTimezone={handleOpenTimezone}
          onOpenSettings={() => setSettingsModalOpen(true)}
          onInviteGuests={() => setCollaborateModalOpen(true)}
          onGenerateOutput={() => setOutputModalOpen(true)}
          onExportPdf={() => setExportModalOpen(true)}
          onExportCsv={handleDownloadCsv}
          onResetRunner={handleResetRunner}
          writeAccess={writeAccess}
        />
      </nav>
      {writeAccess && <RundownCollaborateModal
        rundownId={rundown.id}
        open={collaborateModalOpen}
        setOpen={setCollaborateModalOpen}
      />}
      {writeAccess && <RundownOutputModal
        rundownId={rundown.id}
        open={outputModalOpen}
        setOpen={setOutputModalOpen}
      />}
      {writeAccess && <RundownExportModal
        rundownId={rundown.id}
        rundownName={rundown.name}
        open={exportModalOpen}
        setOpen={setExportModalOpen}
      />}
      {writeAccess && <RundownTimezoneModal
        rundownId={rundown.id}
        timezone={rundown.timezone}
        open={timezoneModalOpen}
        setOpen={setTimezoneModalOpen}
      />}
      {writeAccess && <RundownSettingsModal
        rundownId={rundown.id}
        startTime={rundown.startTime}
        timezone={rundown.timezone}
        currentCueHighlightColor={rundown.settings?.currentCueHighlightColor}
        rundownLogo={rundown.logo}
        eventLogo={event?.logo}
        open={settingsModalOpen}
        setOpen={setSettingsModalOpen}
      />}
      <UpgradeModal
        open={showUpgradeModal}
        setOpen={setShowUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
        message='Upgrade your account to export the rundown.'
      />
    </>
  )
}

RundownNavbar.propTypes = {
  rundown: PropTypes.object.isRequired,
  event: PropTypes.object,
}
