import { Menu, MenuItem, MenuSeparator } from '../interactives/DropdownMenu.jsx'
import { faHome, faBackwardStep, faDisplay, faLink, faFolder, faArrowUpRightFromSquare, faFilePdf, faFileCsv, faFileExport, faChevronDown, faCog } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function RundownMenu ({
  teamId,
  eventId,
  onOpenSettings,
  onInviteGuests,
  onGenerateOutput,
  onExportPdf,
  onExportCsv,
  onResetRunner,
  writeAccess = false,
}) {
  const navigate = useNavigate()

  return (
    <div className="">
      <Menu label="Rundown" icon={faChevronDown}>
        <MenuItem
          icon={faHome}
          label="Dashboard"
          onClick={() => navigate(`/dashboard/${teamId}`)}
          disabled={!writeAccess}
        />
        {eventId && <MenuItem
          icon={faFolder}
          label="Event"
          onClick={() => navigate(`/dashboard/${teamId}/event/${eventId}`)}
          disabled={!writeAccess}
        />}
        <MenuSeparator />
        <MenuItem
          icon={faCog}
          label="Settings"
          onClick={onOpenSettings}
          disabled={!writeAccess}
        />
        <MenuSeparator />
        <MenuItem
          icon={faLink}
          label="Invite Guests"
          onClick={onInviteGuests}
          disabled={!writeAccess}
        />
        <MenuItem
          icon={faDisplay}
          label="Generate Output"
          onClick={onGenerateOutput}
          disabled={!writeAccess}
        />
        <Menu label="Export as..." icon={faFileExport}>
          <MenuItem
            icon={faFilePdf}
            label="PDF"
            onClick={onExportPdf}
            disabled={!writeAccess}
          />
          <MenuItem
            icon={faFileCsv}
            label="CSV"
            onClick={onExportCsv}
            disabled={!writeAccess}
          />
        </Menu>
        <MenuSeparator />
        <MenuItem
          icon={faArrowUpRightFromSquare}
          label="API Docs"
          onClick={()=> window.open('https://rundownstudio.app/docs/rundown/api/', '_blank', 'noreferrer')}
          disabled={!writeAccess}
        />
        <MenuSeparator />
        <MenuItem
          icon={faBackwardStep}
          label="Reset rundown timing"
          onClick={onResetRunner}
          disabled={!writeAccess}
        />
      </Menu>
    </div>
  )
}

RundownMenu.propTypes = {
  teamId: PropTypes.string.isRequired,
  eventId: PropTypes.string,
  onOpenSettings: PropTypes.func,
  onInviteGuests: PropTypes.func,
  onGenerateOutput: PropTypes.func,
  onExportPdf: PropTypes.func,
  onExportCsv: PropTypes.func,
  onResetRunner: PropTypes.func,
  writeAccess: PropTypes.bool,
}
