import PropTypes from 'prop-types'


export default function EditableHms ({
  hms,
  onUpdate,
  disabled = false,
}) {

  function handleTimeUpdate (update = {}) {
    const newHms = {...hms, ...update}

    Object.keys(newHms).forEach((key) => {
      const maxValue = key === 'hours' ? 99 : 59
      const value = String(newHms[key]).padStart(2, '0')
      const newDigit = value.slice(-1) // get the last digit entered
      const currentDigit = value.slice(-2, -1) // get the second last digit

      let newValue = parseInt(currentDigit + newDigit, 10) // combine digits

      // If user enters a letter, ensure we do not store that
      if(isNaN(newValue)) newValue = 0

      if (newValue > maxValue) {
        newValue = parseInt(newDigit, 10) // if it exceeds, use the last digit only
      }

      newHms[key] = newValue
    })

    onUpdate(newHms)
  }

  return (
    <div className='flex justify-between font-mono text-sm group/hms gap-2'>
      <input
        className='w-1/3 py-0.5 bg-gray-800 group-hover/hms:enabled:brightness-125 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        disabled={disabled}
        value={hms?.hours?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ hours: parseInt(e.target.value || 0) })}
      />
      <input
        className='w-1/3 py-0.5 bg-gray-800 group-hover/hms:enabled:brightness-125 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        disabled={disabled}
        value={hms?.minutes?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ minutes: parseInt(e.target.value || 0) })}
      />
      <input
        className='w-1/3 py-0.5 bg-gray-800 group-hover/hms:enabled:brightness-125 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        disabled={disabled}
        value={hms?.seconds?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ seconds: parseInt(e.target.value || 0) })}
      />
    </div>
  )
}

EditableHms.propTypes = {
  hms: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
